import React, { Component } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import ItemListing from '../components/item-listing'
import Card from '../components/card'
import styled from 'styled-components'
import HeroArea from '../components/hero-area'
import EstimateForm from '../components/estimate-form'
import ContactForm from '../components/ContactForm'
import { OurPartners } from '../components/OurPartners'
import { StaticImage } from "gatsby-plugin-image"
const MainContentContainer = styled.div`
  h1 {
    margin-bottom: 0.25em;
  }
`

const SidebarContainer = styled.div``
const Spacer = styled.div`
  height: 5px;
`
const MapTitle = styled.p`
  text-align: center;
  font-size: 12px;
  padding-top: 10px;
  font-weight: 600;
`

class PageTemplate extends Component {
  render() {
    let pageData = this.props.data.contentfulPage

    const showContactForm = this.props.location.pathname.includes('contact')
    const SidebarColumn = () => {
      const sideBarCards = pageData.sidebar.map((sidebar, index) => (
        <Card key={index} sidebar={sidebar} />
      ))

      return sideBarCards
    }

    return (
      <Layout pageProps={this.props} headerTransparent={this.props.location.pathname.includes('about')}>
        <SEO
          title={pageData.metaTitle}
          description={pageData.metaDescription}
          robots={pageData.metaRobotsContent}
        />

        {pageData && pageData.heroArea ? <HeroArea heroContent={pageData && pageData.heroArea} /> : ''}




        <div
          className={
            'max-w-7xl mx-auto  px-4 sm:px-6 lg:px-8 py-12' +
            (showContactForm ? ' contact-us-page' : '')
          }
        >




          <div className="flex flex-wrap">
            <div
              className={
                pageData.sidebar && !showContactForm
                  ? 'md:w-2/3 md:pr-4 prose'
                  : 'max-w-2xl mx-auto'
              }
            >

              {pageData.body.body.includes('{{CustomAboutSection}}') && <>
                <h1>About Us</h1>
                <p>At KYPD Service, we are committed to providing exceptional plumbing services to the Kentucky area. With years of experience and a team of skilled technicians, we have established ourselves as a trusted provider of quality workmanship, reliable service, and complete customer satisfaction.</p>

                <div className='mt-8 flex flex-wrap md:flex-nowrap	 md:space-x-8 space-y-4 md:space-y-0'>
                  <div className='w-full md:w-2/3'>
                    <div className="relative ">
                      <StaticImage
                        src="../images/kypdteam.jpeg"
                        alt="KYPD Team"
                        className="!m-0 h-full w-full rounded-xl bg-gray-900/5 object-cover shadow-xl md:min-h-[429px]"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <p className='!mt-1 font-normal text-center text-gray-600 font-display'>KYPD Team</p>

                  </div>
                  <div className='w-full flex md:block md:w-1/3 space-x-4 md:space-x-0 md:space-y-4 h-full'>
                    <div className='w-1/2 md:w-full'>
                      <div className="relative">
                        <StaticImage
                          src="../images/RemoteMediaFile_6619.jpeg"
                          alt="KYPD at Work"
                          className="!m-0 w-full rounded-xl bg-gray-900/5 object-cover shadow-xl"
                        />
                        <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />

                      </div>
                      <p className='!mt-1 font-normal text-center text-gray-600 font-display'>KYPD at Work</p>
                    </div>
                    <div className='w-1/2 md:w-full'>
                      <div className="relative">
                        <StaticImage
                          src="../images/294648862_822232769160210_1340219085497298409_n.jpeg"
                          alt="KYPD Office"
                          className="!m-0 w-full rounded-xl bg-gray-900/5 object-cover shadow-xl"
                        />
                        <div className="h-full pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />

                      </div>
                      <p className='!mt-1 font-normal text-center text-gray-600 font-display'>KYPD Office</p>
                    </div></div>
                </div>
              </>}

              {pageData && pageData.body && pageData.body.body ? (
                <div dangerouslySetInnerHTML={{ __html: pageData.body.body.replace(/\{\{([^}]+)\}\}/gi, '') }} />
              ) : (
                ''
              )}
              <Spacer />
              {showContactForm ? (
                <ContactForm />
              ) : (
                ''
              )}

              {pageData.itemListing ? (
                <ItemListing itemList={pageData.itemListing} />
              ) : (
                ''
              )}
            </div>


            {pageData.sidebar && !showContactForm ? (
              <div className="w-full md:w-1/3 md:pl-4 space-y-4 md:space-y-6">
                <SidebarColumn />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>

        {this.props.location.pathname.includes('about') && <OurPartners />}
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query pageQuery($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      id
      pageTitle
      metaTitle
      metaDescription
      slug
      metaRobotsContent
      itemListing {
        id
        serviceTitle
        excerpt
        slug
        heroImage {
          gatsbyImageData(width: 370, quality: 70)
        }
      }

      heroArea {
        id
        name
        heroTitle
        description {
          description
        }
        heroImage {
          gatsbyImageData(width: 1600, quality: 100)
        }
        primaryActionBtnLabel
        primaryActionBtnLink
      }
      body {
        body
      } 
      sidebar {
        id
        title
        content {
          id
          content
        }
        testimonialCategory {
          id
          name
        }
        cssClasses
      }
    }
  }
`






//  mb-6  font-extrabold text-2xl md:text-3xl text-base md:text-lg text-gray-600 




// aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg